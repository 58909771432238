var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aside',{staticClass:"main-menu",class:{ open: _vm.open, full: _vm.full }},[_c('div',{staticClass:"menu-container",class:{
				short: _vm.shortScreen,
				stuck: _vm.stuck,
				bn: _vm.site === 'bn',
				fd: _vm.site === 'fd',
				pz: _vm.site === 'pz'
			}},[(_vm.topItems.length > 0)?_c('menu-list',{attrs:{"items":_vm.topItems},on:{"showingSubMenu":_vm.updateShowingSubMenu}}):_vm._e(),(_vm.rideItems.length > 0 && _vm.hasStoredRide)?_c('menu-list',{attrs:{"items":_vm.rideItems},on:{"showingSubMenu":_vm.updateShowingSubMenu}}):_vm._e(),_c('menu-list',{attrs:{"items":_vm.items,"filter-items-encoded":_vm.filterItemsEncoded,"initial-side-menu-encoded":_vm.initialSideMenuEncoded,"initial-parent-menu-encoded":_vm.initialParentMenuEncoded,"siteTag":_vm.siteTag,"garageEnabled":_vm.garageEnabled,"menuToOpen":_vm.menuToOpen},on:{"showingSubMenu":_vm.updateShowingSubMenu}}),(_vm.isAuthenticated && !_vm.showingSubMenu)?_c('div',{staticClass:"menu-list"},[_c('div',{staticClass:"list-title"},[_vm._v("Account")]),_vm._l((_vm.filteredAccountLinks),function(item,index){return _c('div',{key:index + '_' + item.label,staticClass:"list-items"},[_c('a',{attrs:{"href":item.url},domProps:{"innerHTML":_vm._s(item.label)}})])}),_c('div',{staticClass:"list-separator"})],2):_vm._e(),(!_vm.showingSubMenu)?_c('div',{staticClass:"menu-list"},[_c('div',{staticClass:"list-title"},[_vm._v("Questions?")]),_vm._l((_vm.siteLinks),function(item,index){return _c('div',{key:index + '_' + item.label,staticClass:"list-items"},[_c('a',{attrs:{"href":item.url},domProps:{"innerHTML":_vm._s(item.label)}})])}),(!_vm.isAuthenticated)?_c('div',{staticClass:"list-separator"}):_vm._e()],2):_vm._e(),(!_vm.isAuthenticated && !_vm.showingSubMenu)?_c('div',{staticClass:"menu-list"},[_c('div',{staticClass:"list-title"},[_vm._v("Account")]),_vm._m(0),_vm._m(1)]):_vm._e(),_c('div',{staticStyle:{"height":"80px"}},[_vm._v(" ")])],1)]),_c('span',{staticClass:"menu-overlay",class:{ open: _vm.open },on:{"click":function($event){return _vm.toggleMenu(null)}}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-items"},[_c('a',{attrs:{"href":"/account/login"}},[_vm._v("Sign In")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-items"},[_c('a',{attrs:{"href":"/account/register"}},[_vm._v("Sign Up")])])
}]

export { render, staticRenderFns }