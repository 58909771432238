<template>
	<div>
		<aside
			class="main-menu"
			:class="{ open, full }"
		>
			<div
				class="menu-container"
				:class="{
					short: shortScreen,
					stuck: stuck,
					bn: site === 'bn',
					fd: site === 'fd',
					pz: site === 'pz'
				}"
			>
	
			<!-- INJECTED MENU(S) -->
			<menu-list
				v-if="topItems.length > 0"
				:items="topItems"
				@showingSubMenu="updateShowingSubMenu"
			/>
			<menu-list
				v-if="rideItems.length > 0 && hasStoredRide"
				:items="rideItems"
				@showingSubMenu="updateShowingSubMenu"
			/>
			<menu-list
				:items="items"
				@showingSubMenu="updateShowingSubMenu"
				:filter-items-encoded="filterItemsEncoded"
				:initial-side-menu-encoded="initialSideMenuEncoded"
				:initial-parent-menu-encoded="initialParentMenuEncoded"
				:siteTag="siteTag"
				:garageEnabled="garageEnabled"
				:menuToOpen="menuToOpen"
			/>
	
				<!-- ACCOUNT MENU -->
				<div
					v-if="isAuthenticated && !showingSubMenu"
					class="menu-list"
				>
					<div class="list-title">Account</div>
					<div
						class="list-items"
						v-for="(item, index) in filteredAccountLinks"
						:key="index + '_' + item.label"
					>
						<a
							:href="item.url"
							v-html="item.label"
						></a>
					</div>
					<div class="list-separator"></div>
				</div>
	
				<!-- SITE MENU -->
				<div
					v-if="!showingSubMenu"
					class="menu-list"
				>
					<div class="list-title">Questions?</div>
					<div
						v-for="(item, index) in siteLinks"
						:key="index + '_' + item.label"
						class="list-items"
					>
						<a
							:href="item.url"
							v-html="item.label"
						></a>
					</div>
					<div
						v-if="!isAuthenticated"
						class="list-separator"
					></div>
				</div>
	
				<!-- SIGN IN -->
				<div
					v-if="!isAuthenticated && !showingSubMenu"
					class="menu-list"
				>
					<div class="list-title">Account</div>
					<div class="list-items">
						<a href="/account/login">Sign In</a>
					</div>
					<div class="list-items">
						<a href="/account/register">Sign Up</a>
					</div>
				</div>
	
				<!-- MENU BOTTOM SPACING -->
				<div style="height: 80px">&nbsp;</div>
			</div>
		</aside>
		<span
			class="menu-overlay"
			:class="{ open: open }"
			@click="toggleMenu(null)"
		></span>
	</div>
</template>

<script>
import data from '@/mixins/data.js';
import MenuButton from './MenuButton.vue';
import GarageMenuButton from '../garage/GarageMenuButton.vue';
import LocationButton from './LocationButton.vue';
import MenuList from './MenuList.vue';
import garage from '@/mixins/garage.js';
import Vue from 'vue';

export default {
	name: 'main-menu',

	mixins: [garage, data],
	components: { MenuButton, GarageMenuButton, LocationButton, MenuList },

	props: {
		topItems: { type: Array, default: () => [] },
		rideItems: { type: Array, default: () => [] },
		startOpen: { type: Number, default: 0 },
		stuck: { type: Boolean, default: false },
		shipping: { default: false },
		site: { type: String, default: 'pz' },
		customerName: { type: String },
		isAuthenticated: { type: Number },
		garageEnabled: { type: Boolean, default: false },
		siteTag: { type: String },
		filterItemsEncoded: { type: String },
		sitePhone: { type: String, default: '000-000-0000' },
		siteTel: { type: String, default: '10000000000' },
		isDocTypeComponent: { type: String, default: "0" },
		initialSideMenuEncoded: { type: String },
		initialParentMenuEncoded: { type: String },
		menuItems: { type: String },
	},

	data() {
		return {
			shortScreen: false,
			windowWidth: 0,
			showingSubMenu: false,
			siteLinks: [
				{ label: 'Order Status', url: '/order', sites: ['pz', 'bn', 'fd'] },
				{ label: 'Help & Info', url: '/info/help', sites: ['pz', 'bn', 'fd'] },
				{
					label: 'Contact Us',
					url: '/help/contact',
					sites: ['pz', 'bn', 'fd'],
				},
			],
			isMobile: false,
			openState: null,
			menuToOpen: '',
			accountLinks: [
				{
					label: 'Your Account',
					url: '/account',
					sites: ['pz', 'bn', 'fd'],
					cda: false,
				},
				{
					label: 'Your Orders',
					url: '/account/order',
					sites: ['pz', 'bn', 'fd'],
					cda: false,
				},
				{
					label: 'Your Garage',
					url: '/account/garage',
					sites: ['pz'],
					cda: false,
				},
				{
					label: 'Purchase Orders',
					url: '/account/invoice',
					sites: ['pz', 'bn'],
					cda: true,
				},
				{
					label: 'Clients',
					url: '/account/client',
					sites: ['pz', 'bn'],
					cda: true,
				},
				{
					label: 'Your Lists',
					url: '/account/list',
					sites: ['pz', 'bn', 'fd'],
					cda: false,
				},
				{
					label: 'Sign Out',
					url: '/account/login?logout',
					sites: ['pz', 'bn', 'fd'],
					cda: false,
				},
			],
		};
	},

	watch: {
		open(v) {
			if (v) {
				this.checkForStoredRide();

				if (this.hasStoredRide) this.getStoredRide();
			}
		},
	},

	methods: {
		whenResized() {
			if (this.windowWidth < 769) {
				this.isMobile = true;
			} else {
				this.isMobile = false;
			}
		},
		toggleMenu(value = null) {
			if (value !== null) {
				this.openState = value;
			} else {
				this.openState = !this.openState;
			}
			const menuToggled = new CustomEvent('menuToggled', { detail: { open: this.openState } });
			window.dispatchEvent(menuToggled);
		},
		toggleGarage() {
			this.openState = !this.openState;
			const toggleGarage = new CustomEvent('toggleGarage');
			window.dispatchEvent(toggleGarage);
		},
		goToComponent() {
			if (this.garageFitmentId) {
				window.location = `/catalog/v1/${this.garageFitmentId}/${this.storedRideName
					.replace(/[-]/g, '')
					.replace(/\s/g, '-')}`;
			} else {
				window.location = '/search?q=parts&ui=typeahead';
			}
		},
		updateShowingSubMenu(value) {
			this.showingSubMenu = value;
		},
		toggleMenuHandler(e) {
			this.menuToOpen = '';
			Vue.nextTick(() => {
				if (e.detail && e.detail.menuItem) {
					this.menuToOpen = e.detail.menuItem;
				}
				this.toggleMenu(e.detail ? e.detail.open : null);
			});
		}
	},

	computed: {
		full() {
			return this.isCatalogPage &&
				this.isMobile &&
				!this.isComponentPage &&
				!Object.keys(this.filterItems).length;
		},
		open() {
			if (this.openState === null) {
				return !!this.startOpen && !this.isMobile;
			}
			return this.openState;
		},
		filteredSiteLinks() {
			return this.siteLinks.filter((l) => {
				return l.sites.includes(this.siteTag);
			});
		},
		filteredAccountLinks() {
			return this.accountLinks.filter((l) => {
				if (this.isCda) return l.sites.includes(this.siteTag) || l.cda;

				if (l.label.includes('Sign Out')) {
					return l.url += `&reurl=${this.currentPageEncoded}`;
				}

				if (l.label.includes('Your Garage')) {
					return this.garageEnabled;
				}

				return l.sites.includes(this.siteTag);
			});
		},
		filterItems() {
			if (!this.filterItemsEncoded) return {};
			return this.parseBase64(this.filterItemsEncoded) || {};
		},
		isComponentPage() {
			return parseInt(this.isDocTypeComponent ?? 0);
		},
		isCatalogPage() {
			return window.location.pathname.includes('catalog/');
		},
		items() {
			if (!this.menuItems) return [];
			return this.parseBase64(this.menuItems) || {};
		},
	},

	mounted() {
		this.checkForStoredRide();

		if (this.hasStoredRide) {
			this.getStoredRide();
		}

		// let label = 'Call Us at <strong>' + this.sitePhone + '</strong>';
		// let tel = 'tel:' + this.siteTel;

		// this.siteLinks.push({ label: label, url: tel, sites: ['pz', 'bn', 'fd'] });

		// if (this.siteTag == 'pz') {
		// 	label = 'Text Us at <strong>229-800-4096</strong>';
		// 	tel = 'sms:+12298004096';
		// }

		// if (this.siteTag == 'bn') {
		// 	label = 'Text Us at <strong>229-210-3105</strong>';
		// 	tel = 'sms:+12292103105';
		// }

		// this.siteLinks.push({ label: label, url: tel, sites: ['pz', 'bn', 'fd'] });

		const height = window.innerHeight;

		if (height <= 780) this.shortScreen = true;

		this.windowWidth = window.innerWidth;
		if (window.innerWidth < 769) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
		window.resizeListener = window.addEventListener('resize', () => {
			this.windowWidth = window.innerWidth;
			this.whenResized();
		});
		window.addEventListener('toggleMenu', this.toggleMenuHandler);
		if (this.isCatalogPage && this.isMobile && !this.isComponentPage && !Object.keys(this.filterItems).length) {
			this.toggleMenu(true);
		}
		if (this.open && !this.isMobile) {
			const menuToggled = new CustomEvent('menuToggled', { detail: { open: this.open } });
			window.dispatchEvent(menuToggled);
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', window.resizeListener);
		window.removeEventListener('toggleMenu', this.toggleMenuHandler);
	},
};
</script>

<style lang="scss">
span.menu-overlay {
	z-index: 1;
	display: none;
	position: absolute;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0);
	pointer-events: none;
	left: 0;
	transition: all 0.1s ease-in-out;
	&.open {
		background-color: rgba(0, 0, 0, 0.45);
		pointer-events: all;
	}
}
aside.main-menu {
	background: white;
	border-right: 1px solid #dbdbdb;
	height: 0;
	width: 0;
	overflow: hidden;
	transition: width 0.2s ease-in-out;

	/* width */
	::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f3f3f3;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: black;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #777;
	}

	&.open {
		width: 250px;
		max-width: 250px;
		height: 100%;
	}

	.menu-container {
		height: 100%;
		overflow-y: auto;
		&.bn {
			.menu-list {
				color: #3777bc;
				.list-items {
					a:hover, button:hover {
						background-color: #3777bc;
						background-color: color(display-p3 0.215686 0.466667 0.737255 / 1.000000);
					}
				}
			}
		}

		&.fd {
			.menu-list {
				color: #33ac00;
				.list-items {
					a:hover, button:hover {
						background-color: #33ac00;
						background-color: color(display-p3 0.200000 0.674510 0.000000 / 1.000000);
					}
				}
			}
		}

		&.pz {
			.menu-list {
				color: #c3161c;
				.list-items {
					a:hover, button:hover {
						background-color: #c3161c;
						background-color: color(display-p3 0.764706 0.086275 0.109804 / 1.000000);
					}
				}
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			transition: all 0.2s ease-in-out;
			li {
				&.separator {
					margin: 0;
				}
			}
		}

		.menu-list {
			transition: all 0.2s ease-in-out;
			padding: 0px 10px;
			.list-title {
				color: black;
				font-size: 1.8rem;
				font-weight: bold;
				text-transform: uppercase;
				padding: 0.5em .6em;
				&.text-sm {
					font-size: 1.5rem;
				}
				&.bold {
					font-weight: 800;
				}
				.list-separator {
					margin: 0 0.5em 1em;
				}
			}

			.list-items {
				display: flex;
				flex-direction: column;

				&:first-child {
					padding-top: 1em;
				}

				a,
				button {
					border-radius: 0;
					display: block;
					font-size: 1.5rem;
					font-weight: 600;
					text-decoration: none;
					transition: all 100ms ease-in-out;
					padding: 0.5em .8em;
					width: 100%;
					&.back-btn,
					&.start-over-btn {
						display: flex;
						align-items: center;
						gap: 0.5rem;
						justify-content: flex-start;
						padding: 0.5em 1em;
						&:hover {
							gap: 0.15rem;
						}
					}
					&.start-over-btn {
						margin-left: auto;
						justify-content: flex-end !important;
						&:hover {
							padding: inherit 2em inherit 0;
						}
					}
					&:hover {
						color: white;
						text-decoration: none;
					}
				}
			}

			div.list-separator {
				margin: 1em 2em;
				border-bottom: 1px solid #e4e4e4;
			}
		}
	}
}
.menu-shade {
	position: absolute;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	pointer-events: none;

	transition: background-color 0.1s ease-in-out;

	z-index: 99;

	&.open {
		background-color: rgba(0, 0, 0, 0.45);
		pointer-events: all;
	}

	div.outside {
		width: 100%;
		height: 100%;
		position: absolute;
		pointer-events: none;

		&.open {
			pointer-events: all;
		}
	}
}

@media screen and (min-width: 1536px) {
	aside.main-menu {
		&.open {
			width: 320px;
			max-width: 320px;
		}
	}
}

@media screen and (max-width: 991px) {
	aside.main-menu {
		&.open {
			width: 35vw;
			max-width: 35vw;
			&.full {
				width: 100%;
				max-width: 100%;
			}
		}

		div.menu-container {
			.menu-list {
				.list-items {
					a,
					button {
						padding: 1em 1.5em;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	span.menu-overlay {
		&.open {
			display: block;
		}
	}
	aside.main-menu {
		position: absolute;
		height: 100vh;
		z-index: 100;
		overflow-y: scroll;
		&.open {
			display: block;
			width: 80vw;
			max-width: 80vw;
			&.full {
				width: 100%;
				max-width: 100%;
			}
		}

		div.menu-container {
				&.short {
				height: 70%;

				&.stuck {
					height: 80%;
				}
			}
			.menu-list {
				.list-items {
					a,
					button {
						padding: 1em 1.5em;
					}
				}
			}
		}
	}
}
</style>
