export default {
    methods: {
        decideImageURL(cdn, imageURL = '', size = 'full', quality = 100, format = 'auto') {
            let imageBank;
            let cdnParameters;
            // deal with sizing
            switch(size) {
                case "thumbnail":
                    cdnParameters = `width=40,quality=50,fit=scale-down,format=${format}`;
                    break;
                case "full":
                    cdnParameters = `width=768,quality=90,fit=scale-down,format=${format}`;
                    break;
                case "card":
                    cdnParameters = `width=270,quality=80,fit=scale-down,format=${format}`;
                    break;
                default:
                    cdnParameters = `width=${size},quality=${quality},fit=scale-down,format=${format}`;
                    break;
            }

            // actual image urls
            if(imageURL.includes('.cloudfront.net/')) {
                imageBank = `${imageURL} + ?${cdnParameters}`;
            } else {
                imageBank = `https://${cdn}/cdn-cgi/image/${cdnParameters}/${imageURL}`;
            }

            return imageBank;
        },
        createSrcSet(cdn, imageUrl = '', format = 'auto') {
            const imageSizes = [320, 414, 768, 960, 1024, 1280, 1532];
            const srcSet = imageSizes.map(size => {
                return `${this.decideImageURL(cdn, imageUrl, size, 90, format)} ${size}w`;
            }).join(', ');

            return srcSet;
        }
    }
}