import Cookies from 'js-cookie';

export default {
	data() {
		return {
			localGarage: [],
			storedRide: null,
			hasStoredRide: false,
			addingToGarage: false,
			addedToGarage: false,
		};
	},

	computed: {
		localGarageFiltered() {
			if (!this.hasStoredRide) return this.localGarage;

			return this.localGarage.filter((g) => {
				return g.name !== this.storedRide.name;
			});
		},
		storageName() {
			if (this.partFinder) {
				return this.site + '-' + this.brand + '-finder';
			}

			if (this.catalog) {
				return this.site + '-' + 'catalog-stored-ride';
			}

			return this.site + '-stored-ride';
		},
		storedRideName() {
			if (this.hasStoredRide) {
				if (this.storedRide.name) return this.storedRide.name;
				if (Array.isArray(this.storedRide)) {
					if (this.storedRide[0].make) {
						return `${this.storedRide[0].year} ${this.storedRide[0].make} ${this.storedRide[0].model}`;
					}
				}
				if (this.storedRide.make) {
					return `${this.storedRide.year} ${this.storedRide.make} ${this.storedRide.model}`;
				}
				if (this.storedRide.values) {
					const year = this.findStoredRideValueEntry('Year');
					const make = this.findStoredRideValueEntry('Make');
					const model = this.findStoredRideValueEntry('Model');
					return `${year} ${make} ${model}`;
				}
			}
			return null;
		},
		storedRideModel() {
			if (this.hasStoredRide) {
				if (this.storedRide.model) return this.storedRide.model;
				if (Array.isArray(this.storedRide)) {
					if (this.storedRide[0].model) return this.storedRide[0].model;
				}
				if (this.storedRide.values) {
					return this.findStoredRideValueEntry('Model');
				}
			} else if (window.location.pathname.includes('catalog/v1')) {
				const data = window.location.pathname
					.split('/')
					.find((entry) => entry.includes('-'))
					.replaceAll('-', ' ');
				return data.split(' ').at(-1);
			}
			return null;
		},
		garageId() {
			if (this.hasStoredRide) {
				if (Array.isArray(this.storedRide)) {
					if (this.storedRide[0].garage_id.id) return this.storedRide[0].garage_id.id;
				}
				if (this.storedRide.garage_id) {
					if (this.storedRide.garage_id.id) return this.storedRide.garage_id.id;
				}
			}
			return null;
		},
		garageFitmentId() {
			if (this.hasStoredRide) {
				if (Array.isArray(this.storedRide)) {
					if (this.storedRide[0].id) return this.storedRide[0].id;
					else if (this.storedRide[0].garage_id.fitment) return this.storedRide[0].garage_id.fitment;
				}
				if (this.storedRide.id) return this.storedRide.id;
				if (this.storedRide.garage_id) {
					if (this.storedRide.garage_id.fitment) return this.storedRide.garage_id.fitment;
				}
				// sometimes the backend only provides this
				if (this.storedRide.catalog) {
					if (this.storedRide.catalog.fitment) return this.storedRide.catalog.fitment;
				}
			}
			return null;
		},
	},

	methods: {
		checkForStoredRide() {
			if (Cookies.get('selected-ride')) {
				this.hasStoredRide = true;
			} else {
				this.hasStoredRide = !!localStorage.getItem(this.storageName); // turns the value into a boolean
			}

			if (!Cookies.get('selected-ride') && this.hasStoredRide) {
				this.getLocalGarage();
				this.getStoredRide();

				if (this.garageId !== null) {
					this.localGarage.forEach((ride, index) => {
						if (ride.id === this.garageId) {
							this.localGarage.splice(index, 1);
						}
					});
				}

				this.updateLocalGarage();

				this.hasStoredRide = false;
				this.storedRide = null;
			}
		},
		getStoredRide() {
			if (Cookies.get('selected-ride')) {
				let cookie = Cookies.get('selected-ride');
				let cookieSanitized = cookie.replace(/\+/g, ' ');

				this.storedRide = JSON.parse(cookieSanitized);
				return;
			}

			this.storedRide = JSON.parse(localStorage.getItem(this.storageName));
		},
		setStoredRide(ride, storage = false) {
			this.storedRide = ride;

			//   if (!this.partFinder) {
			// 	Cookies.set("selected-ride", JSON.stringify(this.storedRide));
			//   }

			localStorage.setItem(storage ? storage : this.storageName, JSON.stringify(this.storedRide));
			this.checkForStoredRide();

			// this.$emit("new-ride");
		},
		clearStoredRide() {
			localStorage.removeItem(this.storageName);
			this.storedRide = null;
			this.hasStoredRide = false;

			Cookies.remove('selected-ride');
			// this.loading = true;
			// this.$emit("found", null);

			// EventBus.$emit("init");
			// this.$emit("new-ride");
		},
		getLocalGarage() {
			if (localStorage.getItem(this.site + '-local-garage') !== null) {
				let garage = [];
				let storage = JSON.parse(localStorage.getItem(this.site + '-local-garage'));
				if (Array.isArray(storage)) {
					garage = storage;
				}

				this.localGarage = garage.filter(ride => ride !== null);
			}

			if (this.localGarage.length === 0) localStorage.removeItem(this.site + '-local-garage');
		},
		updateLocalGarage() {
			if (localStorage.getItem(this.site + '-local-garage') !== null) {
				localStorage.setItem(this.site + '-local-garage', JSON.stringify(this.localGarage));
			}
		},
		getBackendGarage() {
			const options = {
				method: 'GET',
			};
			fetch('/garage/api/summary', options)
				.then((response) => {
					if (response.ok) {
						return response.json();
					}
				})
				.then((results) => {
					this.localGarage = results;
				});
		},
		removeParam(key) {
			history.replaceState &&
				history.replaceState(
					null,
					'',
					location.pathname +
						location.search.replace(new RegExp(`[\?&]${key}=[^&]+`), '').replace(/^&/, '?') +
						location.hash
				);
		},
		removeFitmentParam() {
			this.removeParam('fitment');
		},
		removeUseFitmentParam() {
			this.removeParam('use_fitment');
		},
		findStoredRideValueEntry(type) {
			if (this.hasStoredRide) {
				if (this.storedRide.values) {
					return Object.values(this.storedRide.values.find((ride) => Object.keys(ride) == type)).join('');
				}
			}
			return null;
		},
		async addToGarage(ride) {
			this.addedToGarage = false;
			this.addingToGarage = true;
			const test = window.location.hostname.includes('localhost') ? 'localhost' : '';

			const id = this.getRideFitmentId(ride);

			try {
				return await fetch(`${test}/garage/add/${id}?api=1`);
			} catch (error) {
				console.log(error);
				this.addingToGarage = false;
				const { status } = error.response;
				// Server error
				if (status >= 500 && status < 600) {
					this.error = 'Sorry, there was an unexpected problem looking up your model.';
				} else {
					this.error = error;
				}
				throw error;
			}
		},
		addToLocalGarage() {
			let garage = [];

			if (localStorage.getItem(this.site + '-local-garage') !== null) {
				garage = JSON.parse(localStorage.getItem(this.site + '-local-garage'));
			}

			garage.push(this.storedRide);

			localStorage.setItem(this.site + '-local-garage', JSON.stringify(garage));
			this.localGarage = JSON.parse(localStorage.getItem(this.site + '-local-garage'));
		},
		getRideFitmentId(ride) {
			let id = null;
			if (ride.garage_id && ride.garage_id.fitment) {
				id = ride.garage_id.fitment;
			} else if (ride.id) {
				id = ride.id;
			} else if (ride.catalog) {
				id = ride.catalog.fitment;
			}

			if (id == undefined || id == null || id == '') {
				throw Error('Sorry, there was an unexpected problem looking up your model.');
			}

			return id;
		},
		checkRideInLocalGarage(ride) {
			for (let index = 0; index < this.localGarage.length; index++) {
				const garageRide = this.localGarage[index];
				if (this.getRideFitmentId(garageRide) === this.getRideFitmentId(ride)) {
					throw Error('This ride is already in your garage.');
				}
			}
		},
		isRideSelected(ride) {
			if (this.storedRide == null) {
				return false;
			}
			
			return this.getRideFitmentId(ride) == this.getRideFitmentId(this.storedRide);
		},
		isRideLoading(ride) {
			if (this.isRideSelected(ride) && this.loading) {
				return true;
			}

			return false;
		},
		isRideBeingCleared(ride) {
			if (this.isRideSelected(ride) && this.clearing) {
				return true;
			}

			return false;
		},
	},
};
